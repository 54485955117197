import React, {useEffect, useLayoutEffect, useState} from "react"
import {Collapse, NavbarToggler,} from 'reactstrap';
import logo from "../images/nav/nav-logo.png";
import globe from "../images/globe.svg";
import info_circle from "../images/info_circle.svg";
import diamond from "../images/diamond.svg";
import learning from "../images/learning.svg";
import people from "../images/people.svg";
import grant from "../images/grant.svg";
import calendar from "../images/calendar.svg";
import content from "../images/content.svg";
import node from "../images/node.svg";
import { useLocation } from '@reach/router';
import {
    FaArrowRight,
    FaDiscord,
    FaGithub,
    FaHubspot,
    FaInstagram,
    FaLinkedin,
    FaReddit,
    FaTiktok,
    FaTwitter,
    FaYoutube
} from "react-icons/fa";
const Navigation = (props) => {
    console.log('Navigation',props)
    const [isOpen, setIsOpen] = useState(false);
    const toggle = (event) => setIsOpen(!isOpen)
    const [homepage,setHomepage] = useState(false);
    const [hackatron,setHackatron] = useState(false)
    useEffect(() => {
        const span = document.querySelector('.navbar-toggler-icon')
        if (isOpen) {
            span.classList.add("navbar-toggler-icon-close")
        } else {
            span.classList.remove("navbar-toggler-icon-close")
        }
    }, [isOpen])

    const closeMenu = () => {
        setIsOpen(false);
        closeNavDropDowns();
    };

    const show = "d-block active";
    const hide = "d-none";

    const closeNavDropDowns = () => {
        closeNavDropDown1();
        closeNavDropDown2();
        closeNavDropDown3();
        closeNavDropDown4();
        closeNavDropDown5();
    }

    const [isNavDropDown1Open, setIsNavDropDown1Open] = useState(hide);
    const toggleNavDropDown1 = () => {
        if (isNavDropDown1Open === show) {
            setIsNavDropDown1Open(hide)
        } else {
            closeNavDropDowns();
            setIsNavDropDown1Open(show)
        }
    };
    const closeNavDropDown1 = () => setIsNavDropDown1Open(hide);

    const [isNavDropDown2Open, setIsNavDropDown2Open] = useState(hide);
    const toggleNavDropDown2 = () => {
        if (isNavDropDown2Open === show) {
            setIsNavDropDown2Open(hide)
        } else {
            closeNavDropDowns();
            setIsNavDropDown2Open(show)
        }
    };
    const closeNavDropDown2 = () => setIsNavDropDown2Open(hide);

    const [isNavDropDown3Open, setIsNavDropDown3Open] = useState(hide);
    const toggleNavDropDown3 = () => {
        if (isNavDropDown3Open === show) {
            setIsNavDropDown3Open(hide)
        } else {
            closeNavDropDowns();
            setIsNavDropDown3Open(show)
        }
    };
    const closeNavDropDown3 = () => setIsNavDropDown3Open(hide);

    const [isNavDropDown4Open, setIsNavDropDown4Open] = useState(hide);
    const toggleNavDropDown4 = () => {
        if (isNavDropDown4Open === show) {
            setIsNavDropDown4Open(hide)
        } else {
            closeNavDropDowns();
            setIsNavDropDown4Open(show)
        }
    };
    const closeNavDropDown4 = () => setIsNavDropDown4Open(hide);

    const [isNavDropDown5Open, setIsNavDropDown5Open] = useState(hide);
    const toggleNavDropDown5 = () => {
        if (isNavDropDown5Open === show) {
            setIsNavDropDown5Open(hide)
        } else {
            closeNavDropDowns();
            setIsNavDropDown5Open(show)
        }
    };
    const closeNavDropDown5 = () => setIsNavDropDown5Open(hide);
    let isHackathon = false;
    if (typeof window !== "undefined") {
        //TODO 1.need confirm "hackathon" is spell err 2.this is temp plan
        isHackathon = window.location.pathname === "/hackathon/";
    }
    

    return (
        <header className="position-sticky sticky-top">
            <nav
                className={(isHackathon === true ? 'navbar-hackathon' : '') + (props.location.pathname === "/hackatron/" ? 'navbar-hackatron' : '') + (props.location.pathname === "/" ? 'navbar-homepage' : '')  + " navbar navbar-expand-lg navbar-light d-flex"}>
                <div className="container-fluid no-gutters px-0 w-100">
                    <div className="d-flex logo-wrapper">
                        <a onClick={closeMenu} href="/" className="my-auto">
                            <img src={logo} alt="Tron Logo" className="logo"/>
                        </a>
                        <div className="ml-2 d-sm-none d-block logo-wrapper-mobile-text">
                            <a onClick={closeMenu} className="" href="/">TRON DAO</a>
                        </div>
                    </div>
                    <div className="navbar-name d-none d-sm-block col-4 col-lg-3">
                        <div className="mt-3">
                            <a onClick={closeMenu} className="float-left" href="/">TRON DAO</a>
                        </div>
                    </div>
                    <NavbarToggler onClick={toggle} className="ml-auto mr-2 mr-md-4 my-2"/>
                    <Collapse isOpen={isOpen} navbar>
                        <ul className="navbar-nav small mr-0 ml-lg-auto">
                            <li className="nav-item dropdown position-static">
                                <button
                                    className={(isNavDropDown1Open === show ? 'active' : '') + ' nav-link w-100 dropdown-toggle'}
                                    type="button" id="dropdownMenuButton0" onClick={toggleNavDropDown1}>
                                    USE TRON
                                </button>
                                <div style={{borderBottom: "0.5px solid #232323", width: "85%"}}
                                     className='mx-auto d-block d-sm-block d-lg-none'></div>
                                <div className={isNavDropDown1Open + ' dropdown-menu'}
                                     aria-labelledby="dropdownMenuButton0">
                                    <div className="dropdown-b d-flex flex-wrap">
                                        <div
                                            className="dropdown-spacer dropdown-spacer text-left d-inline-block align-top justify-content-center col-md-11 col-lg-3 offset-md-0 col-10 offset-1">
                                            <p className="dropdown-menu-subtitle">
                                                <FaHubspot className="mr-2"/>
                                                NETWORK
                                            </p>
                                            <ul>
                                                <li>
                                                    <a onClick={closeMenu} href="https://tron.network/wallet?lng=en"
                                                       target="_blank" rel="noopener noreferrer"
                                                       id="nav-network-wallet">
                                                        Find Wallet
                                                        <FaArrowRight className="navbar-outside-link"/>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a onClick={closeMenu} href="https://tron.network/trx?lng=en"
                                                       target="_blank" rel="noopener noreferrer" id="nav-network-trx">
                                                        Get TRX
                                                        <FaArrowRight className="navbar-outside-link"/>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a onClick={closeMenu} href="https://tron.network/bttc?lng=en"
                                                       target="_blank" rel="noopener noreferrer"
                                                       id="nav-network-bittorrent">
                                                        BitTorrent Chain
                                                        <FaArrowRight className="navbar-outside-link"/>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a onClick={closeMenu} href="https://tron.network/bttc?lng=en"
                                                       target="_blank" rel="noopener noreferrer"
                                                       id="nav-network-staking">
                                                        Staking
                                                        <FaArrowRight className="navbar-outside-link"/>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a onClick={closeMenu}
                                                       href="https://tronscan.org/#/sr/representatives" target="_blank"
                                                       rel="noopener noreferrer" id="nav-network-tronscan">
                                                        TRONSCAN
                                                        <FaArrowRight className="navbar-outside-link"/>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a onClick={closeMenu} href={`/buy-trx`} target="_blank" rel="noopener noreferrer" id="nav-buy-trx">
                                                    Buy TRX*
                                                    <FaArrowRight className="navbar-outside-link"/>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a onClick={closeMenu} href={`/on-off-ramp`} target="_blank" rel="noopener noreferrer" id="nav-on-off-ramp">
                                                    On/Off Ramp
                                                    <FaArrowRight className="navbar-outside-link"/>
                                                    </a>
                                                </li>
                                                <li className="nav-tip-binance">*In Countries Where blockchain.com pay Is Available*</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li className="nav-item dropdown position-static">
                                <button
                                    className={(isNavDropDown2Open === show ? 'active' : '') + ' nav-link w-100 dropdown-toggle'}
                                    type="button" id="dropdownMenuButton1" onClick={toggleNavDropDown2}>
                                    INITIATIVES
                                </button>
                                <div style={{borderBottom: "0.5px solid #232323", width: "85%"}}
                                     className='mx-auto d-block d-sm-block d-lg-none'></div>
                                <div className={isNavDropDown2Open + ' dropdown-menu'}
                                     aria-labelledby="dropdownMenuButton1">
                                    <div className="dropdown-b d-flex flex-wrap">
                                        <div
                                            className="dropdown-spacer dropdown-spacer text-left d-inline-block align-top pr-5 justify-content-center col-md-11 col-lg-3 offset-md-0 col-10 offset-1">
                                            <p className="dropdown-menu-subtitle">
                                                <img src={globe} alt="Globe" className="mr-2"/>
                                                PROGRAMS
                                            </p>
                                            <ul>
                                                <li>
                                                    <a onClick={closeMenu} href="/"
                                                       target="_blank" rel="noopener noreferrer"
                                                       id="nav-programs-charity">
                                                        Charity
                                                        <FaArrowRight className="navbar-outside-link"/>
                                                    </a>
                                                </li>
                                                {/* <li>
                                                    <a onClick={closeMenu} href="/initiatives/academy"
                                                       rel="noopener noreferrer"
                                                       id="nav-programs-tron-academy">
                                                        TRON Academy
                                                    </a>
                                                </li> */}
                                                <li>
                                                    <a onClick={closeMenu} href="https://apenft.io/#/launchpad"
                                                       target="_blank" rel="noopener noreferrer"
                                                       id="nav-programs-launchpad">
                                                        NFT Launchpad
                                                        <FaArrowRight className="navbar-outside-link"/>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        {/* <div
                                            className="dropdown-spacer dropdown-spacer text-left d-inline-block align-top px-5 justify-content-center col-md-11 col-lg-3 offset-md-0 col-10 offset-1">
                                            <p className="dropdown-menu-subtitle">
                                                <img src={info_circle} alt="Info Circle" className="mr-2"/>
                                                ABOUT
                                            </p>
                                            <ul>
                                                <li>
                                                    <a onClick={closeMenu} href="/initiatives/about/history/"
                                                       rel="noopener noreferrer"
                                                       id="nav-about-history">
                                                        History
                                                    </a>
                                                </li>
                                                <li>
                                                    <a onClick={closeMenu} href="/initiatives/about/research/"
                                                       rel="noopener noreferrer"
                                                       id="nav-about-research">
                                                        Research
                                                    </a>
                                                </li>
                                            </ul>
                                        </div> */}
                                    </div>
                                </div>
                            </li>

                            <li className="nav-item dropdown position-static">
                                <button
                                    className={(isNavDropDown3Open === show ? 'active' : '') + ' nav-link dropdown-toggle w-100'}
                                    type="button" id="dropdownMenuButton2" onClick={toggleNavDropDown3}>
                                    #BUIDL the FUTURE
                                </button>
                                <div style={{borderBottom: "0.5px solid #232323", width: "85%"}}
                                     className='mx-auto d-block d-sm-block d-lg-none'></div>
                                <div className={isNavDropDown3Open + ' dropdown-menu'}
                                     aria-labelledby="dropdownMenuButton2">
                                    <div className="dropdown-b d-flex flex-wrap">
                                        <div
                                            className="dropdown-spacer dropdown-spacer text-left d-inline-block align-top pr-5 justify-content-center col-md-11 col-lg-3 offset-md-0 col-10 offset-1">
                                            <p className="dropdown-menu-subtitle">
                                                <img src={diamond} alt="Diamond" className="mr-2"/>
                                                PATHWAYS
                                            </p>
                                            <ul>
                                                <li>
                                                    <a onClick={closeMenu} href="/resources/get-started/"
                                                       rel="noopener noreferrer"
                                                       id="nav-resources-get-started">
                                                        Get Started
                                                    </a>
                                                </li>
                                                <li>
                                                    <a onClick={closeMenu} href="/resources/multimedia/"
                                                       rel="noopener noreferrer"
                                                       id="nav-resources-multimedia">
                                                        Multimedia
                                                    </a>
                                                </li>
                                                <li>
                                                    <a onClick={closeMenu} href="/resources/gaming/"
                                                       rel="noopener noreferrer"
                                                       id="nav-resources-gaming">
                                                        Gaming
                                                    </a>
                                                </li>
                                                <li>
                                                    <a onClick={closeMenu} href="/resources/finance/"
                                                       rel="noopener noreferrer"
                                                       id="nav-resources-finance">
                                                        Finance
                                                    </a>
                                                </li>
                                                <li>
                                                    <a onClick={closeMenu} href="/resources/art/"
                                                       rel="noopener noreferrer"
                                                       id="nav-resources-art">
                                                        Art
                                                    </a>
                                                </li>
                                                <li>
                                                    <a onClick={closeMenu} href="/resources/governance/"
                                                       rel="noopener noreferrer"
                                                       id="nav-resources-governance">
                                                        Governance
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div
                                            className="dropdown-spacer text-left d-inline-block align-top px-5 justify-content-center col-md-11 col-lg-3 offset-md-0 col-10 offset-1">
                                            <p className="dropdown-menu-subtitle">
                                                <img src={learning} alt="Learning" className="mr-2"/>
                                                RESOURCES
                                            </p>
                                            <ul>
                                                {/* <li>
                                                    <a onClick={closeMenu} href=""
                                                       target="_blank" rel="noopener noreferrer"
                                                       id="nav-learning-blueprint">
                                                        TRON Blueprint
                                                    </a>
                                                </li> */}
                                                <li>
                                                    <a onClick={closeMenu} href="https://developers.tron.network/"
                                                       target="_blank" rel="noopener noreferrer" id="nav-learning-docs">
                                                        TRON Docs
                                                        <FaArrowRight className="navbar-outside-link"/>
                                                    </a>
                                                </li>
                                                {/* <li>
                                                    <a onClick={closeMenu} href="/tronpedia/"
                                                       rel="noopener noreferrer"
                                                       id="nav-learning-tronpedia">
                                                        TRONpedia
                                                    </a>
                                                </li> */}
                                                <li>
                                                    <a onClick={closeMenu}
                                                       href="https://tron.network/static/doc/white_paper_v_2_0.pdf"
                                                       target="_blank" rel="noopener noreferrer"
                                                       id="nav-learning-whitepaper">
                                                        White Paper
                                                        <FaArrowRight className="navbar-outside-link"/>
                                                    </a>
                                                </li>
                                                {/* <li>
                                                    <a onClick={closeMenu} href="/learning/faq/"
                                                       rel="noopener noreferrer" id="nav-learning-faqs">
                                                        FAQs
                                                    </a>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item dropdown position-static">
                                <button
                                    className={(isNavDropDown4Open === show ? 'active' : '') + ' nav-link dropdown-toggle w-100'}
                                    type="button" id="dropdownMenuButton3" onClick={toggleNavDropDown4}>COMMUNITY
                                </button>
                                <div style={{borderBottom: "0.5px solid #232323", width: "85%"}}
                                     className='mx-auto d-block d-sm-block d-lg-none'></div>
                                <div className={isNavDropDown4Open + ' dropdown-menu'}
                                     aria-labelledby="dropdownMenuButton3">
                                    <div className="dropdown-b d-flex flex-wrap flex-column flex-lg-row">
                                        <div
                                            className="dropdown-spacer text-left d-inline-block align-top pl-lg-0 justify-content-center col-md-11 col-lg-2 offset-md-0 col-10 offset-1">
                                            <p className="dropdown-menu-subtitle">
                                                <img src={people} alt="People Icon" className="mr-2"/>
                                                TOGETHER
                                            </p>
                                            <ul>
                                                <li><a onClick={closeMenu} rel="noopener noreferrer"
                                                       id="nav-community-home" href="/community/home">Be a TRONIC (HOME)</a></li>
                                                <li><a onClick={closeMenu} target="_blank" rel="noopener noreferrer"
                                                       id="nav-community-branding" href="/#">Branding</a></li>
                                                <li><a onClick={closeMenu} target="_blank" rel="noopener noreferrer"
                                                       id="nav-community-trading" href="/community/trading">Trading</a></li>
                                                {/* <li><a onClick={closeMenu} target="_blank" rel="noopener noreferrer"
                                                       id="nav-community-tronic" href="/#">TRONIC</a></li> */}
                                            </ul>
                                        </div>
                                        {/* <div
                                            className="dropdown-spacer text-left d-inline-block align-top justify-content-center col-md-11 col-lg-2 offset-md-0 col-10 offset-1">
                                            <p className="dropdown-menu-subtitle">
                                                <img src={grant} alt="Contract Icon" className="mr-2"/>
                                                GRANTS
                                            </p>
                                            <ul>
                                                <li><a onClick={closeMenu} rel="noopener noreferrer"
                                                       id="nav-grants-for-community"
                                                       href="/ambassador-grants/#grants-community/">Community</a>
                                                </li>
                                                <li><a onClick={closeMenu} rel="noopener noreferrer"
                                                       id="nav-grants-for-developers"
                                                       href="/ambassador-grants/#grants-developers/">Developers</a>
                                                </li>
                                                <li><a onClick={closeMenu} rel="noopener noreferrer"
                                                       id="nav-grants-for-influencers"
                                                       href="/ambassador-grants/#grants-influencers/">Influencers</a>
                                                </li>
                                            </ul>
                                        </div> */}
                                        <div
                                            className="dropdown-spacer text-left d-inline-block align-top justify-content-center col-md-11 col-lg-2 offset-md-0 col-10 offset-1">
                                            <p className="dropdown-menu-subtitle">
                                                <img src={calendar} alt="Calendar Icon" className="mr-2"/>
                                                EVENTS
                                            </p>
                                            <ul>
                                                <li>
                                                    <a onClick={closeMenu} href="/hackatron/" rel="noopener noreferrer"
                                                       id="nav-events-hackathon">
                                                        HackaTRON
                                                    </a>
                                                </li>
                                                {/* <li>
                                                    <a onClick={closeMenu} href="/#" rel="noopener noreferrer"
                                                       id="nav-events-whale-night">
                                                        TRON Whale Night
                                                    </a>
                                                </li> */}
                                                <li>
                                                    <a onClick={closeMenu} href="/community/events/"
                                                       rel="noopener noreferrer"
                                                       id="nav-events-upcoming">
                                                        Events
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div
                                            className="dropdown-spacer text-left d-inline-block align-top justify-content-center col-md-11 col-lg-2 offset-md-0 col-10 offset-1">
                                            <p className="dropdown-menu-subtitle">
                                                <img src={content} alt="Content Icon" className="mr-2"/>
                                                LEARN
                                            </p>
                                            <ul>
                                                <li>
                                                    <a onClick={closeMenu} href="/podcasts/" rel="noopener noreferrer"
                                                       id="nav-content-podcast">
                                                        Podcasts
                                                    </a>
                                                </li>
                                                <li>
                                                    <a onClick={closeMenu} href="/videos/" rel="noopener noreferrer"
                                                       id="nav-content-videos">
                                                        Videos
                                                    </a>
                                                </li>
                                                <li>
                                                    <a onClick={closeMenu} href="/press/" rel="noopener noreferrer"
                                                       id="nav-content-press-releases">
                                                        Press Releases
                                                    </a>
                                                </li>
                                                <li>
                                                    <a onClick={closeMenu} href="/blog/" rel="noopener noreferrer"
                                                       id="nav-content-blog">
                                                        Blog
                                                    </a>
                                                </li>
                                                <li>
                                                    <a onClick={closeMenu} href="https://forum.trondao.org"
                                                       id="nav-content-forum" rel="noopener noreferrer">
                                                        Forum<FaArrowRight className="navbar-outside-link"/>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a onClick={closeMenu} href="/community/policy-report/" rel="noopener noreferrer"
                                                       id="nav-content-blog">
                                                        TRON Policy Report
                                                    </a>
                                                </li>
                                                <li>
                                                    <a onClick={closeMenu} href="/tronpedia/" rel="noopener noreferrer"
                                                       id="nav-content-blog">
                                                        TRONPEDIA
                                                    </a>
                                                </li>                                                
                                                {/* <li>
                                                    <a onClick={closeMenu} href=""
                                                       id="nav-content-forum" rel="noopener noreferrer">
                                                        TRON+<FaArrowRight className="navbar-outside-link"/>
                                                    </a>
                                                </li> */}
                                            </ul>
                                        </div>
                                        <div className="d-flex flex-column"
                                             style={{marginLeft: "0px", marginTop: "0px"}}>
                                            <div
                                                className="dropdown-spacer text-left d-inline-block align-top px-0  justify-content-center col-md-11 col-lg-8 offset-lg-0 col-10 offset-1">
                                                <p className="dropdown-menu-subtitle" style={{marginTop: "0px"}}>
                                                    <img src={node} alt="Node Icon" className="mr-2"/>
                                                    SOCIAL
                                                </p>
                                                <ul>
                                                    <li className="d-sm-inline-flex d-block">
                                                        <a onClick={closeMenu} id="nav-social-twitter"
                                                           href="https://twitter.com/trondao"
                                                           className="navbar-social-icon"
                                                           target="_blank" rel="noopener noreferrer">
                                                            <FaTwitter className="text-primary"/>
                                                            <span className="pl-1">Twitter</span>
                                                        </a>
                                                        <div className="navbar-social-spacer"/>
                                                        <a onClick={closeMenu} id="nav-social-instagram"
                                                           href="https://www.instagram.com/trondaoofficial/"
                                                           className="navbar-social-icon" target="_blank"
                                                           rel="noopener noreferrer">
                                                            <FaInstagram className="text-primary"/>
                                                            <span className="pl-1">Instagram</span>
                                                        </a>
                                                    </li>
                                                    <br className="d-none d-sm-block"/>
                                                    <li className="d-sm-inline-flex d-block">
                                                        <a onClick={closeMenu} id="nav-social-youtube"
                                                           href="https://www.youtube.com/channel/UC5OPOGRq02iK-0T9sKse_kA?view_as=subscriber"
                                                           className="navbar-social-icon" target="_blank"
                                                           rel="noopener noreferrer">
                                                            <FaYoutube className="text-primary"/><span
                                                            className="pl-1">Youtube</span>
                                                        </a>
                                                        <div className="navbar-social-spacer"/>
                                                        <a onClick={closeMenu} id="nav-social-tiktok"
                                                           href="https://www.tiktok.com/@trondaoofficial"
                                                           className="navbar-social-icon" target="_blank"
                                                           rel="noopener noreferrer">
                                                            <FaTiktok className="text-primary"/><span
                                                            className="pl-1 text-nowrap">Tik Tok</span>
                                                        </a>
                                                    </li>
                                                    <br className="d-none d-sm-block"/>
                                                    <li className="d-sm-inline-flex d-block">
                                                        <a onClick={closeMenu} id="nav-social-discord"
                                                           href="https://discord.com/invite/pn6WWmXtQV"
                                                           className="navbar-social-icon" target="_blank"
                                                           rel="noopener noreferrer">
                                                            <FaDiscord className="text-primary"/><span
                                                            className="pl-1">Discord</span>
                                                        </a>
                                                        <div className="navbar-social-spacer"/>
                                                        <a onClick={closeMenu} id="nav-social-linkedin"
                                                           href="https://www.linkedin.com/company/trondao"
                                                           className="navbar-social-icon" target="_blank"
                                                           rel="noopener noreferrer">
                                                            <FaLinkedin className="text-primary"/><span
                                                            className="pl-1">LinkedIn</span>
                                                        </a>
                                                    </li>
                                                    <br className="d-none d-sm-block"/>
                                                    <li className="d-sm-inline-flex d-block">
                                                        <a onClick={closeMenu} id="nav-social-reddit"
                                                           href="https://www.reddit.com/r/Tronix/"
                                                           className="navbar-social-icon" target="_blank"
                                                           rel="noopener noreferrer">
                                                            <FaReddit className="text-primary"/><span
                                                            className="pl-1">Reddit</span>
                                                        </a>
                                                        <div className="navbar-social-spacer"/>
                                                        <a onClick={closeMenu} id="nav-social-github"
                                                           href="https://github.com/tronprotocol/"
                                                           className="navbar-social-icon" target="_blank"
                                                           rel="noopener noreferrer">
                                                            <FaGithub className="text-primary"/><span
                                                            className="pl-1">Github</span>
                                                        </a>
                                                    </li>
                                                    <br className="d-none d-sm-block"/>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item dropdown position-static">
                                <button
                                    className={(isNavDropDown5Open === show ? 'active' : '') + ' nav-link w-100 dropdown-toggle'}
                                    type="button" id="dropdownMenuButton4" onClick={toggleNavDropDown5}>
                                    ECOSYSTEM
                                </button>
                                <div className={isNavDropDown5Open + ' dropdown-menu'}
                                     aria-labelledby="dropdownMenuButton4">
                                    <div className="dropdown-b d-flex flex-wrap">
                                        <div
                                            className="dropdown-spacer text-left d-inline-block align-top  justify-content-center col-md-11 col-lg-3 offset-md-0 col-10 offset-1">
                                            <p className="dropdown-menu-subtitle">
                                                <img src={globe} alt="Globe" className="mr-2"/>
                                                ECOSYSTEM
                                            </p>
                                            <ul>
                                                <li>
                                                    <a onClick={closeMenu} href="/ecosystem-fund/"
                                                       rel="noopener noreferrer"
                                                       id="nav-ecosystem-fund">
                                                        Ecosystem Fund
                                                    </a>
                                                </li>
                                                <li>
                                                    <a onClick={closeMenu} href="/ecosystem-climate/"
                                                       rel="noopener noreferrer"
                                                       id="nav-ecosystem-climate">
                                                        Climate Program
                                                    </a>
                                                </li>
                                                <li>
                                                    <a onClick={closeMenu} href="/ecosystem/"
                                                       rel="noopener noreferrer"
                                                       id="nav-ecosystem-feature-projects">
                                                        Explore Projects
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </Collapse>
                    <div className="navbar-filler d-none d-xl-block"/>
                </div>
            </nav>
        </header>
    )
}

export default Navigation
