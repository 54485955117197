// Only one item MUST have the "default: true" key

module.exports = {
  en: {
    default: true,
    path: `en`,
    locale: `en-US`,
    dateFormat: `DD/MM/YYYY`,
    siteLanguage: `en`,
    ogLanguage: `en_US`,
    defaultTitle: `English`,
    defaultDescription: `Default TronDao`,
  },
  // zh_cn: {
  //   path: `zh-cn`,
  //   locale: `zh-CN`,
  //   dateFormat: `DD.MM.YYYY`,
  //   siteLanguage: `zh_CN`,
  //   ogLanguage: `zh_CN`,
  //   defaultTitle: `Simplified Chinese`,
  //   defaultDescription: `TronDao Mainland China`,
  // },
}
